@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* TOOGLE BUTTON */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff6800;
}

input:focus + .slider {
  box-shadow: 0 0 1px #b6b6b6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* TOOGLE BUTTON */

/* Track */
::-webkit-scrollbar-track {
  background: rgb(211, 211, 211);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(143, 143, 143);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(82, 82, 82);
}

.font-weight-bold {
  font-weight: 550 !important;
}

.navbar {
  height: 55px;
  width: 100%;
  background-color: #262626;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: sticky;
  z-index: 1;
  top: 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.checkboxSpread {
  background-color: #566c79;
  padding: 10px !important;
  padding: 0px;
  cursor: pointer;
  color: white;
  border: none !important;
}

.checkboxSpread2 {
  background-color: white;
  cursor: pointer;
  padding: 10px !important;
  cursor: pointer;
}

.flyDocLogo {
  margin-top: 0rem;
  width: 50px;
}

.cyint {
  display: block;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.navIcons {
  width: 1.2rem;
}

.container-xl {
  padding: 3% 30%;
}

.nav_btn {
  width: 200px;
  border-radius: 20px;
  height: 35px;
}

.nav_btn2 {
  width: 200px;
  border-radius: 20px;
  height: 35px;
  background-color: #0075db;
  border: none !important;
}

.nav_btn2:hover {
  width: 200px;
  border-radius: 20px;
  height: 35px;
  background-color: #005d92;
  border: none !important;
}

.log-out-icon {
  border: none;
  background-color: transparent;
}

.btn {
  text-shadow: 0px 1px #000000af;
}

.account {
  height: 25px;
  width: 25px;
}

.settingsnav {
  height: 28px;
  width: 28px;
}

.account-2 {
  height: 25px;
  width: 25px;
}

.admin_text {
  color: white;
  padding-right: 5px;
}

.mainicon {
  background-color: #37383b;
  border-radius: 8px 0px 0px 8px;
}

.scardm:hover {
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.scardm {
  background-color: #ffffff;
  border: 1px solid #ebebeb !important;
}

/* .side_nav {
  border-bottom: 1px dashed #005d92;
} */
.upgifback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 6rem;
  border-radius: 10px;
  box-shadow: 4px 4px #0000002d;
  z-index: 1;
  cursor: progress;
}

.upgif {
  height: 6rem;
  width: auto;
  object-fit: contain;
}

.card {
  border-radius: 10px;
}

.headertop {
  margin-top: 75px;
}

.dashmain {
  background-color: #f5f6f7;
}

.dashmenu {
  margin-top: 0px;
}

.card-state {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(194, 194, 194, 0.2) 0px 4px 12px;
  height: 95px;
}

.print-content {
  padding: 0.2rem 0.4rem;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* dash-board left */
.dash-left-bar-0 {
  /* background-image: url(./Image/dashboardBack.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.dash-left-bar-1 {
  margin-top: 6rem;
}

.dash-left-bar-2 {
  border-radius: 10px;
  width: 14rem;
}

.dash-left-bar-3 {
  width: 2.5rem;
  height: 3rem;
  object-fit: contain;
  margin-bottom: 10px;
}

.dash-left-bar-4 {
  margin-top: -1rem;
}

.dash-left-bar-5 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #262626;
  text-shadow: none !important;
}

.dash-left-bar-6 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: -1rem;
  color: #ffa200;
  text-shadow: none !important;
}

/* invoice design */

.invHeader {
  flex-direction: row;
}

.invoice-logo {
  width: 8rem;
}

.addborder {
  height: 40px;
}

.mtop {
  margin-top: -0.5rem;
}

.border-t {
  width: 300px;
  text-align: center;
}

.border-dark {
  border-color: #000000de !important;
}

/* invoice design */

/* admin panel */
.invoice-container {
  margin-left: 80px;
  margin-right: 80px;
}

.invoice-logo {
  width: 10rem;
}

.addborder {
  height: 40px;
}

.mtop {
  margin-top: -0.5rem;
}

.service_details1 {
  width: 200px;
}

.service_details2 {
  width: 300px;
}

.border-t {
  width: 150px;
  text-align: center;
}

.table-bordered.tr {
  border-color: #00000075 !important;
}

/* admin panel */

/* Right bar */
.addcsbutton {
  background-color: #008ada;
  border: none !important;
}

.addcsbutton:hover {
  background-color: #0074b8;
  border: none !important;
}

.cus-Card {
  background-image: url("./images/approved.png");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: bottom 10px right 10px;
}

.ven-Card {
  background-image: url("./images/insurance.png");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: bottom 10px right 10px;
}

.ag-Card {
  background-image: url("./images/business.png");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: bottom 10px right 10px;
}

.rightitem {
  text-align: center;
}

.filterbtn {
  background-color: #e8e8e8;
  border: none;
}

.filterbtn:hover {
  background-color: white;
  border: none;
}

.rightn {
  background-color: #ffffff;
  border-radius: 10px;
}

.rightcdata {
  height: 365px;
  overflow-y: auto;
  overflow-x: hidden;
}

.right-bar-0 {
  border-left: 1px solid #ddd;
  background-color: #f5f5f5;
  margin: 0px;
  padding: 0px;
  align-self: center;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 55px;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.left-bar-0 {
  background-color: #212c30;
  margin: 0px;
  padding: 0px;
  position: fixed;
  z-index: 1000;
  top: 55px;
  left: 0;
  height: 100%;
  overflow: auto;
}

.right-bar-b {
  border-radius: 0.3rem;
  height: 2.5rem;
  margin-left: 20px;
  margin-right: 20px;
}

.right-bar-1 {
  border-radius: 0.3rem;
  height: 3rem;
  margin-left: 20px;
  margin-right: 20px;
}

.right-bar-nb {
  border-radius: 5px;
  height: 2.7rem;
}

.right-bar-a {
  font-size: 1rem;
  font-weight: 400;
  color: white;
}

.right-bar-a:hover {
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: #ffa200;
}

.right-bar-2 {
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
}

.right-bar-2:hover {
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  color: #ffa200;
}

.right-bar-2a {
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
}

.right-bar-3 {
  font-size: 20px;
  font-weight: 700;
}

.right-bar-4 {
  width: 30px;
  height: 20px;
}

.right-bar-5 {
  height: 2.2rem;
}

.right-bar-6 {
  font-size: 16px;
  color: #262626;
}

.right-bar-7 {
  width: 20px;
  height: 20px;
}

.right-bar-8 {
  margin-left: 30px;
  margin-right: 30px;
}

.right-bar-9 {
  background-color: #1c2b35;
  height: 6rem;
  border-radius: 10px;
  padding: 1rem;
}

.right-bar-9a {
  background: url("./images/passenger.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #1c2b35;
  height: 5.5rem;
  border-radius: 10px;
  padding: 1rem;
}

.right-bar-9b {
  background: url("./images/income.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #1c2b35;
  height: 5.5rem;
  border-radius: 10px;
  padding: 1rem;
}

.right-bar-9c {
  background: url("./images/money.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #1c2b35;
  height: 5.5rem;
  border-radius: 10px;
  padding: 1rem;
}

.right-bar-9d {
  background: url("./images/expenses.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-color: #1c2b35;
  height: 5.5rem;
  border-radius: 10px;
  padding: 1rem;
}

.right-bar-10 {
  margin-left: -1rem;
  margin-top: -0.1rem;
}

.right-bar-11 {
  width: 80px;
  height: 80px;
}

.right-bar-12 {
  padding: 0px;
  margin-top: -10px;
}

.right-bar-13 {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.right-bar-14 {
  font-size: 20px;
  margin-top: -1.4rem;
  font-weight: 500;
  color: #ffa200;
}

/* right */
.right-1 {
  width: 15rem;
  height: 3rem;
  border-radius: 10px;
}

.right-11 {
  width: 15rem;
}

.right-2 {
  height: 2rem;
  width: 3rem;
}

.right-3 {
  font-size: 0.7rem;
  height: 2rem;
  width: 5rem;
}

.right-4 {
  border-radius: 10px;
  width: 15rem;
  height: 7rem;
}

.right-5 {
  width: 4rem;
  height: 5.3rem;
}

.addCustomer {
  margin-top: 50px;
}

.addVendor {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 30px;
}

.addBank {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 30px;
}

.infotext {
  font-size: 16px;
  font-weight: 500;
}

.sChooseIcon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.borderBoths {
  border-right: 1px dashed #565656;
  border-left: 1px dashed #565656;
}

.borderBoth {
  border-right: 1px dashed #565656;
  border-left: 1px dashed #565656;
}

.borderRight {
  border-right: 1px dashed #565656;
}

.cusData {
  font-size: 14px;
}

.sldesign {
  background-color: #009400;
  color: white;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.dflight_data {
  background-color: #edf5f6;
  font-size: 15px;
}

.pad1 {
  padding-top: 1.5px;
}

.dflex {
  display: grid;
  grid-template-columns: auto auto;
  position: relative;
}

.staff_data {
  background-color: #edf5f6;
  font-size: 15px;
}

.centerelement {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.searchbox {
  height: 40px;
  padding-left: 50px;
  font-size: 16px;
}

.searchbox2 {
  height: 40px;
  padding-left: 50px;
  font-size: 18px;
}

.filterbox {
  height: 50px;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #262626;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
}

.filterbox:hover {
  color: #ffa200;
  background-color: #f0f0f0;
}

.sChooseIcon2 {
  height: 20px;
  width: 20px;
  object-fit: contain;
  position: absolute;
  top: 10px;
  left: 15px;
}

.sChooseIcon3 {
  height: 20px;
  width: 20px;
  object-fit: contain;
  position: absolute;
  top: 10px;
  left: 10px;
}

.c_name {
  font-size: 18px;
  color: #000;
}

.idshow {
  font-size: 14px;
  color: #777777;
}

.deleteModal {
  top: -8%;
}

.mp-Modal {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.mp-Modal-backdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}

.statusbttn {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #07a507;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.statusbttn:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #009400;
  border: none;
  border-radius: 5px;
}

.transbttn {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #0075db;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.transbttn:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #005d92;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.ptbttn {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #0078bd;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.ptbttn:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #005d92;
  border: none;
  border-radius: 5px;
}

.ptrbttn {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #cd1023;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.ptrbttn:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #a00010;
  border: none;
  border-radius: 5px;
}

.btndlet {
  height: 2rem;
  font-size: 14px;
  padding-top: 5px;
  border-radius: 50px;
  background-color: #565656;
}

.btndlet:hover {
  height: 2rem;
  font-size: 14px;
  border-radius: 50px;
  color: #ffa200;
  background-color: #262626;
}

.btnyes {
  height: 2rem;
  font-size: 14px;
  padding-top: 5px;
  border-radius: 50px;
}

.btnyes:hover {
  height: 2rem;
  font-size: 14px;
  border-radius: 50px;
  color: #ffffff;
  background-color: #0081cc;
}

.anbtn {
  text-align: center;
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  background-color: #e9ffe2;
  border: none;
  border-radius: 5px;
}

.upbttn {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  background-color: #f9f9f9;
  border: 1px solid #262626;
  border-radius: 5px;
  text-shadow: none !important;
}

.upbttn:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  background-color: #ececec;
  border: 1px solid #262626;
  border-radius: 5px;
}

.bupdtbttn {
  height: 20px;
  width: 40px;
  font-size: 12px;
  color: #ffffff;
  background-color: #303030;
  border: none;
  border-radius: 10px;
}

.bupdtbttn:hover {
  font-size: 12px;
  color: white;
  background-color: #005d92;
  border: none;
  border-radius: 50px;
}

.additioninfo_bttn {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #0085f7;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.additioninfo_bttn:hover {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #0075db;
  border: none;
  border-radius: 5px;
}

.adddeal {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  background-color: #f7f7f7;
  border: none;
  border-radius: 5px;
  text-shadow: none;
  cursor: not-allowed !important;
}

.bankinfo_bttn {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #0f1e27;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.bankinfo_bttn:hover {
  color: white;
  background-color: #132a38;
}

.staticon {
  margin-top: 0.4rem;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.staticon-2 {
  margin-top: 3px;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.sStatus {
  width: 30%;
  padding: 1px;
  height: 35px;
  background-color: #172730;
  border-radius: 5px;
  border: none !important;
  cursor: pointer;
}

.sStatus:hover {
  background-color: #0b1922;
}

.staticonI {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

.sStatusI {
  width: 100%;
  padding: 2px;
  height: 35px;
  background-color: #172730;
  border-radius: 5px;
  cursor: pointer;
}

.sStatusI:hover {
  width: 100%;
  padding: 2px;
  height: 35px;
  background-color: #0f1e27;
  border-radius: 5px;
}

.sStatusOK {
  width: 30%;
  padding: 1px;
  height: 35px;
  background-color: #ffa200;
  border-radius: 5px;
}

.sStatusOK:hover {
  width: 30%;
  padding: 1px;
  height: 35px;
  background-color: #d88900;
  border-radius: 5px;
}

.sStatus-2 {
  padding: 1px;
  height: 35px;
  background-color: #172730;
  border-radius: 5px;
}

.sStatusOK-2 {
  height: 35px;
  background-color: #ffa200;
  border-radius: 5px;
  cursor: pointer;
}

.sStatusOK-2:hover {
  height: 35px;
  background-color: #d88900;
  border-radius: 5px;
}

.sStatusOK-n {
  height: 35px;
  background-color: #177c30;
  border-radius: 5px;
  cursor: pointer;
}

.sStatusOK-n:hover {
  height: 35px;
  background-color: #177c30;
  border-radius: 5px;
}

.sStatusOK-3 {
  padding: 1px;
  height: 80px;
  background-color: #0080f0;
  border-radius: 5px;
  color: white;
  text-shadow: 0px 1px #000000af;
  font-size: 14px;
}

.sStatus-R {
  padding: 1px;
  height: 35px;
  background-color: #ff1d1d;
  border-radius: 5px;
  cursor: pointer;
}

.sStatus-R:hover {
  padding: 1px;
  height: 35px;
  background-color: #cf0f0f;
  border-radius: 5px;
}

.sStatusIN {
  width: 67%;
  padding: 1px;
  height: 35px;
  background-color: #172730;
  border-radius: 5px;
  cursor: pointer;
  border: none !important;
}

.sStatusIN:hover {
  background-color: #0b1922;
}

.sStatus-R2 {
  width: 30%;
  height: 35px;
  background-color: #ff1d1d;
  border-radius: 5px;
  cursor: pointer;
}

.sStatus-R2:hover {
  background-color: #cf0f0f;
}

.sStatus-R6 {
  width: 65%;
  height: 35px;
  background-color: #172730;
  border-radius: 5px;
  cursor: pointer;
}

.sStatus-R6:hover {
  background-color: #0f1e27;
}

.sStatusD {
  width: 30%;
  padding: 1px;
  height: 35px;
  background-color: #ffa200;
  border-radius: 5px;
  cursor: pointer;
}

.sStatusD:hover {
  background-color: #d88900;
}

.clientpp {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.demoidcard {
  display: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none !important;
}

.clientpp2 {
  width: 220px;
  height: 250px;
  object-fit: cover;
}

.cpayment_status {
  top: 10%;
  width: 100%;
}

.cPaymentDialog {
  max-width: 40%;
}

.oldcs {
  top: 7%;
  background-color: #172730a6;
}

.oldCustDialog {
  max-width: 40%;
  height: 80%;
}

.ocsb {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.soc {
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.mDialog {
  position: absolute;
  top: 40px;
  right: -120px;
  width: 70%;
  pointer-events: none;
  z-index: 1060;
}

.mContent {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #212c30;
  background-clip: padding-box;
  outline: 0;
  overflow-y: auto;
}

.active {
  background-color: #30515d;
  border-radius: 50px;
}

.menuIcon {
  width: 25px;
  height: 25px;
}

.dropmenuIcon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

.pModalheadear {
  font-size: 20px;
  padding: 0.5rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #777777;
}

.pModalbody {
  margin-left: 2rem;
  margin-right: 2rem;
}

.fontTypA {
  font-size: 15px;
  font-weight: 500;
}

.pAmountHist {
  font-size: 12px;
  font-style: italic;
}

.paymentUpdate {
  background-color: #ffa200;
  width: 30%;
  color: white;
  font-weight: 400;
  text-shadow: 0px 1px #000000af;
}

.paymentUpdate:hover {
  background-color: #00d35f;
  width: 30%;
  color: white;
  font-weight: 400;
  text-shadow: 0px 1px #000000af;
}

.closeBtn {
  background-color: #243136;
  color: white;
  border-radius: 50px;
}

.closeBtn:hover {
  color: white;
  background-color: #243136;
  color: #ffa200;
}

.makepayment {
  height: 40px;
  background-color: #07a507;
  color: #ffffff;
}

.makepayment:hover {
  height: 40px;
  background-color: #009400;
  color: #ffffff;
}

.old-customer {
  background-color: #777777;
  border-radius: 50px;
  font-size: 14px;
  color: white;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.old-customer:hover {
  background-color: #4d4d4d;
}

.margin-sidebar {
  margin-left: 50px;
  margin-right: 50px;
}

/* Expense */
.expense-table {
  background-color: #0075db;
}

.expense-table2 {
  background-color: white;
}

.nav-tabs {
  border-bottom: none;
}

/* Bank */
.bank-card-text {
  margin-top: -10px;
}

.b_name {
  font-size: 16px;
  color: #000;
}

.b_data {
  font-size: 14px;
}

.balanceText {
  color: rgb(224, 154, 3);
}

.bdtText {
  font-size: 12px;
}

.blanceText {
  font-size: 18px;
}

.btn-filter {
  height: 2.5rem;
  width: 100%;
  background-color: #e8e8e8;
  color: #000;
}

.btn-filterBF {
  height: 2.5rem;
  width: 25%;
  background-color: #e8e8e8;
  color: #000;
}

.sChooseIconF {
  height: 20px;
  width: 20px;
}

.btn-dark {
  height: 2.3rem;
  font-size: 14px;
  width: 100%;
  border-radius: 50px;
}

.btn-darkg {
  height: 2rem;
  font-size: 14px;
  width: 100%;
  border-radius: 50px;
  color: white;
  background-color: #262626;
}

.btn-darkg:hover {
  height: 2rem;
  border-radius: 50px;
  color: #ffa200;
  background-color: #262626;
}

.ammountType {
  color: #ffa200;
  font-size: 18px;
  font-weight: 500;
}

.ammountType2 {
  color: #ffa200;
  font-size: 16px;
  font-weight: 500;
}

/* Table */
thead {
  background-color: grey;
}

.tableBody {
  padding: 0 0;
}

.tableData {
  height: 350px;
  overflow-y: auto;
}

.vpayment_status {
  top: 0%;
}

.vPaymentDialog {
  max-width: 60%;
}

.nav-pills {
  background-color: #f9f9f9;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #262626;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff;
  border-bottom: 4px solid #ffa200;
  border-radius: 0px;
}

.nav-pills .nav-link {
  color: #262626;
  font-size: 16px;
  font-weight: 500;
}

.fa-clock-o {
  position: absolute;
  display: none;
}

.cusIcon {
  font-size: 24px !important;
}

.hourmodal {
  text-align: center;
  top: 35%;
}

.timeSelect {
  width: 20%;
  background-color: #0085f7;
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.timeSelect:hover {
  width: 20%;
  background-color: #0075db;
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.blanceText2 {
  font-size: 22px;
}

.submitbtn {
  font-size: 16px;
  text-shadow: 0px 1px #000000af;
}

.updtbtn {
  font-size: 16px;
  text-shadow: 0px 1px #000000af;
  border-radius: 50px;
}

.sallary {
  font-size: 18px;
}

.paymbttn {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #ffa200;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.paymbttn:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #db8b00;
  border: none;
  border-radius: 5px;
}

.paymbttnD {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #ff0000;
  border: none;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.paymbttnD:hover {
  height: 35px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #c20000;
  border: none;
  border-radius: 5px;
}

.filtBTN {
  outline: none;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 2.2rem;
  background-color: white;
  width: 100%;
}

.filtBTN-2 {
  outline: none;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  height: 2rem;
  background-color: white;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.filtBTN-3 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  height: 40px;
  background-color: white;
  width: 100%;
  border: 1px solid #ddd;
}

.loginbackimage {
  background: url("./images/fdocBack.png");
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
}

.flydoc_logo_login {
  width: 100px;
  object-fit: contain;
}

.demoidcard {
  border: 1px solid #005d92;
}

.login_font {
  font-size: 45px;
  font-weight: 700;
  color: black;
}

.login-card {
  background-color: rgb(255, 255, 255);
}

.me-auto input[type="file"] {
  margin: 0;
  padding: 0;
  height: 30px;
  font-size: 15px;
}

td,
h1,
label {
  text-transform: capitalize;
}

.inimg {
  width: 150px;
  padding: 0px;
  object-fit: contain;
  margin-top: 0px;
  margin-bottom: 0px;
}

.intxt {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 5px;
}

.invright {
  width: 15%;
  font-size: 22px;
}

.invmiddle {
  width: 15%;
  margin-top: 150px;
  font-size: 24px;
}

.invleft {
  width: 85%;
  font-size: 20px;
}

.invleftC {
  width: 40%;
  font-size: 16px;
}

.contentC {
  font-size: 20;
}

.docIMG {
  width: 100%;
}

.addDoc {
  top: 0%;
  background-color: rgba(0, 0, 0, 0.883) !important;
}

.addDocc {
  top: 5%;
}

.m-addDoc {
  border-radius: 0px;
}

.m-doclog {
  max-width: 50%;
}

.imgbreak {
  height: 500px;
  width: 100%;
  object-fit: contain;
}

.carousel-item {
  background-color: #ffffff;
}

.carousel-control-prev {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-control-next {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.passcb {
  height: 50px;
  background-color: #f5f5f5;
  border: 1px solid #589bd4;
  text-shadow: none;
}

.pcmodal {
  top: 8%;
}

.bank_up_modal {
  border-radius: 0px;
  background-color: #f9f9f9;
}

.pcmodalCont {
  border-radius: 10px;
  background-color: #0f1e27;
  color: #ffffff;
}

.roleselect {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  border-color: #d3d3d3;
}

.bankModal {
  top: 20%;
}

.bankmodalCont {
  border-radius: 0px;
}

.search-mb {
  margin-right: 14px;
  margin-left: 14px;
}

.desktophidden {
  display: none;
}

.dedes {
  display: block;
}

.datadel {
  top: 20%;
}

.datadelM {
  border-radius: 0px;
  background-color: #f5f5f5;
}

.dprofile {
  text-decoration: underline;
  color: #005d92;
  font-size: 16px;
  margin-top: 20px;
}

.dprofile:hover {
  text-decoration: underline;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.vcinfo {
  display: flex;
}

.dTra {
  background-color: #d9f3ff;
  border: 1px solid #8edaf8;
  color: rgb(56, 56, 56);
  height: 100px;
  border-radius: 5px;
}

.dTra:hover {
  background-color: #f5f5f5;
  color: rgb(56, 56, 56);
  height: 100px;
  border-radius: 5px;
}

.dTraa {
  background-color: #ff9d00;
  color: white;
  height: 60px;
  border-radius: 5px;
  text-shadow: 0px 1px #000000af;
}

.dTraa:hover {
  background-color: #d38200;
  color: white;
  height: 60px;
  border-radius: 5px;
}

.onprint {
  display: none;
}

.dtran {
  vertical-align: top;
  padding: 0.2rem 0.4rem;
}

.btnav {
  display: none;
}

.tPurch_status {
  top: 0%;
}

/* .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
} */

.tPurchDialog {
  max-width: 100%;
  max-height: 100%;
}

.tdelbtn {
  margin-top: 10px;
  border: none;
  color: red;
  background-color: white;
  text-decoration: underline;
}

.prheadc {
  display: none;
}

.footer {
  display: none;
}

.cview {
  margin-top: -50px;
}

.closeside {
  position: absolute;
  bottom: 5%;
  font-size: 22px;
  font-weight: bold;
  display: none;
}

.cimgedit {
  height: 200px;
  width: auto;
  object-fit: contain;
}

.cusloader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #fafafa;
  z-index: 10000000;
}

.cusloader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadinggif {
  height: 300px;
  width: 300px;
}

.accback {
  background-image: url(./images/accback.svg);
  height: 8rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.animate__animated.animate__zoomIn {
  --animate-duration: 0.5s;
}

.animate__animated.animate__slideInDown {
  --animate-duration: 0.3s;
}

.print-hide {
  display: none;
}

.dropdown-menu {
  border-radius: 5px !important;
  background-color: #ffffff;
  border: 1px solid #ddd;
  width: 100%;
  margin: auto;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dropdown-item {
  margin: 0px;
  padding: 5px;
  text-align: center;
}

.dropdown-divider {
  margin: 0;
}

.bankback {
  background-image: url(./images/7642.svg);
  background-repeat: no-repeat;
  background-position: 95% 90%;
  background-size: 30%;
}

.acc_reportnav {
  position: sticky;
  top: 0;
  background-color: rgb(173, 173, 173);
  color: rgb(49, 49, 49);
}

.btn {
  font-size: 14px !important;
}

.btn-info {
  background-color: #005d92 !important;
}

@media only screen and (max-width: 600px) {
  .nav_btn {
    width: 120px;
    border-radius: 20px;
    height: 30px;
  }

  .admin_text {
    font-size: 14px;
  }

  .navIcons {
    width: 1.3rem;
  }

  .navbar-brand {
    height: 70px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(238, 238, 238);
  }

  .btnav {
    display: block;
    overflow: hidden;
    height: 55px;
    width: 100%;
    background-color: #f9f9f9;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .btnav .bta {
    float: left;
    display: block;
    color: #243136;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 22px;
    border-radius: 0px;
  }

  .btactive {
    color: #ffa200;
    background-color: #eeeeee;
  }

  .bta {
    border: none;
    background-color: #f9f9f9;
  }

  .navbar {
    height: 70px;
    width: 100%;
    background-color: #243136;
    padding-left: 00px;
    padding-right: 10px;
    position: sticky;
    z-index: 1;
    top: 0;
  }

  .flyDocLogo {
    width: 6rem;
  }

  .cyint {
    display: none;
  }

  .admin-mb {
    display: none;
  }

  .mDialog {
    position: absolute;
    top: 60px;
    right: -130px;
    width: 100%;
    pointer-events: none;
    z-index: 1060;
  }

  .active {
    background-color: #304046;
    border-radius: 5px;
    color: #ffa200;
  }

  .activeb {
    color: #ffa200;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3),
      0px -4px 10px rgba(255, 255, 255, 0.3);
  }

  .activec {
    background-color: #e9e9e9;
  }

  .right-bar-1 {
    border-radius: 0.5rem;
    height: 2.8rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .right-bar-8 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .right-bar-9 {
    background-color: #1c2b35;
    height: 6rem;
    border-radius: 10px;
    padding: 1rem;
  }

  .right-bar-9a {
    background: url("./images/passenger.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-color: #1c2b35;
    height: 5.5rem;
    border-radius: 10px;
    padding: 1rem;
  }

  .right-bar-9b {
    background: url("./images/income.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-color: #1c2b35;
    height: 5.5rem;
    border-radius: 10px;
    padding: 1rem;
  }

  .right-bar-9c {
    background: url("./images/money.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-color: #1c2b35;
    height: 5.5rem;
    border-radius: 10px;
    padding: 1rem;
  }

  .right-bar-9d {
    background: url("./images/expenses.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-color: #1c2b35;
    height: 5.5rem;
    border-radius: 10px;
    padding: 1rem;
  }

  .right-bar-10 {
    margin-left: -1rem;
    margin-top: -0.1rem;
  }

  .right-bar-11 {
    width: 80px;
    height: 80px;
  }

  .right-bar-12 {
    padding: 0px;
    margin-top: -10px;
  }

  .right-bar-13 {
    font-size: 24px;
    font-weight: 500;
    color: white;
  }

  .right-bar-14 {
    font-size: 16px;
    margin-top: -1.4rem;
    font-weight: 500;
    color: #ffa200;
  }

  .closeside {
    position: absolute;
    bottom: 5%;
    font-size: 22px;
    font-weight: bold;
    display: block;
    cursor: pointer;
  }

  #sidebar {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .search-mb {
    margin-right: 5px;
    margin-left: 5px;
  }

  .dashmenu {
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .right-bar-0 {
    position: fixed;
    top: 0;
    right: 0;
    width: 0px;
    transition: 0.2s;
    overflow: hidden;
  }

  .mbaddcs {
    display: none;
  }

  .mainicon {
    height: 120px;
    margin: 0;
    margin-top: 10px;
    background-color: #172730;
  }

  .dash-left-bar-3 {
    width: 2rem;
    height: 2.5rem;
    object-fit: contain;
  }

  .dash-left-bar-4 {
    margin-top: -1rem;
  }

  .dash-left-bar-5 {
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }

  .dash-left-bar-6 {
    font-size: 1rem;
    font-weight: 500;
    margin-top: -1rem;
    color: #ffa200;
  }

  .borderBoths {
    border-right: 1px dashed #565656;
    border-left: none;
  }

  .vpayment_status {
    top: 0%;
  }

  .vPaymentDialog {
    max-width: 100%;
  }

  .addVendor {
    margin-left: 05px;
    margin-right: 05px;
    margin-top: 5px;
  }

  .addCustomer {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
  }

  .desktophidden {
    display: block;
  }

  .marginB {
    margin-bottom: 25px;
  }

  .cpayment_status {
    top: 10%;
    width: 100%;
  }

  .cPaymentDialog {
    max-width: 100%;
  }

  .dedes {
    display: none;
  }

  .filtBTN {
    outline: none;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    height: 2.2rem;
    background-color: rgb(255, 255, 255);
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .bankOut {
    background-color: rgba(255, 192, 203, 0.432);
    border-radius: 0px 0px 10px 10px;
  }

  .tableData {
    height: 300px;
    overflow-y: auto;
  }

  .marginTop {
    margin-top: 10px;
  }

  .addBank {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }

  .tabletxt {
    font-size: 12px;
  }

  .m-doclog {
    max-width: 100%;
  }

  .onprint {
    display: none;
  }

  .oldcs {
    top: 0;
    background-color: #172730a6;
  }

  .oldCustDialog {
    max-width: 100%;
    height: 80%;
  }

  .prheadc {
    display: none;
  }

  .agencyh {
    display: none;
  }
}

.ventexth {
  display: none;
}

@media print {
  @import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");

  body {
    font-family: "Courier Prime", monospace;
    color: #000000 !important;
  }

  .ventexth {
    display: block;
  }

  .print-hide {
    display: block;
  }

  .table-bordered tr td {
    border: 1px solid black !important;
    color: #000000 !important;
  }

  .table-bordered tr th {
    border: 1px solid black !important;
    color: #000000 !important;
  }

  .noprint {
    display: none;
  }

  .onprint {
    display: block;
  }

  .dtran {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }

  .dtranHeader {
    display: grid;
    grid-template-columns: auto auto;
  }

  .trtable {
    width: 100%;
  }

  .prbm {
    margin-top: -90px;
  }

  .cview {
    margin-top: 0px;
  }

  .prheadc {
    display: block;
  }

  .footer {
    display: block;
  }

  .stranwidth {
    width: 95.5%;
  }

  .tranreportheader {
    height: 100px;
    border-bottom: 1px dashed #000000;
  }
}

/* ribbon */

.box {
  width: 200px;
  height: 300px;
  position: relative;
  border: 1px solid #bbb;
  background: #eee;
  float: left;
  margin: 20px;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}

.ribbon span {
  font-size: 0.6rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 21px;
  transform: rotate(45deg);
  width: 97px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
